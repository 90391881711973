@import 'styles/config';

.button {
  height: 24px;
  width: 24px;
  margin-top: -12px;
  &.active {
    height: 32px;
    width: 32px;
    margin-top: -16px;
  }
  .shadow {
    margin-top: -6px;
  }
}
