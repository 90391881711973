@import 'styles/config';

.cardWrapper {
  @include hover-supported() {
    .card {
      box-shadow: #{$Px16Clamp} #{$Px16Clamp} 0px #{$color-secondary};
      top: calc(-1*#{$Px16Clamp});
      left: calc(-1*#{$Px16Clamp});
    }
  }
}

.isSmallCardWrapper {
  max-width: 322px;
  text-decoration: none
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 675px;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  position: relative;
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 0px 0px #{$color-secondary};
  top: 0;
  left: 0;
}

.topWrapper {
  position: relative;
}

.packageName {
  position: absolute;
  bottom: #{$Px32Clamp};
  left: #{$Px40Clamp};
  color: $color-background;
  font-size: #{$Px48Clamp};
  text-shadow: 0px 2px 12px rgba(41, 2, 84, 0.28);
  >b {
    font-weight: 800;
  }

  letter-spacing: -1.44px;
}

.packageNameSmall {
  font-size: #{$Px36Clamp};
  left: #{$Px24Clamp};
}

.packageImage {
  height: clamp(14.444rem, 13.638rem + 4.539vw, 17.833rem); // 321px;
  object-fit: cover;
  width: 100%;
}

.smallImage {
  height: clamp(9.444rem, 8.571rem + 4.911vw, 13.111rem); // 236px
  object-fit: cover;
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: #{$Px40Clamp};
  background: $color-background;
  margin-top: auto;
}

.contentWrapperSmall {
  padding: #{$Px24Clamp};
}

.description {
  & strong {
    color: #{$color-secondary};
  }
}

.bottomWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: clamp(1rem, 0.674rem + 0.87vw, 2rem);
  margin-top: auto;

  @media screen and (max-width: $screen-power-package-xxl) {
    flex-direction: column;
    gap: 20px;
  }
}

.smallBottomWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;

  @media screen and (max-width: $screen-power-package-xxl) {
    flex-direction: column;
    gap: 20px;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
}

.firstPriceName,
.firstPriceValue {
  color: #9F9F9F;
  font-weight: 400;
  flex-wrap: nowrap;
  letter-spacing: -0.36px;
}

.firstPriceName {
  font-size: #{$Px12Clamp};
}

.firstPriceValue {
  font-size: #{$Px16Clamp};
  font-size: clamp(0.75rem, 0.668rem + 0.217vw, 1rem);
  text-decoration: line-through;
}

.secondPriceName,
.secondPriceValue {
  flex-wrap: nowrap;
  letter-spacing: -0.36px;
}

.secondPriceName {
  color: #46C28C;
  font-size: #{$Px14Clamp};
  font-weight: 700;
}

.secondPriceValue {
  color: #{$color-secondary};
  font-size: clamp(0.833rem, 0.794rem + 0.223vw, 1rem);
  font-weight: 800;

}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-items: flex-end;
  flex: 3;
}

.smallButtonWrapper {
  position: absolute;

  @media screen and (max-width: $screen-power-package-xxl) {
    position: unset;
  }

  bottom: #{$Px24Clamp};
  right: #{$Px24Clamp};

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.greenBorder {
  border: 2px solid #46C28C;
  border-radius: 8px;
}

.greenBanner {
  background: #46C28C;
  color: white;
  padding: 6.5px 24px;
  font-size: #{$Px18Clamp};

  font-weight: 700;

}