@import 'styles/config';

$indicator: 44px;
$indicator-min: 40px;

.accordionItem {
  transform: translate3d(0, 0, 0);

  &:last-child {
    padding-bottom: 0;

    border: none;
  }

  &__content {
    border-top: 1px solid #e1e1e1;
  }

  &__button {
    display: flex;
    align-items: center;

    padding-top: scale-to-em(24px, $font-size);
    padding-bottom: scale-to-em(24px, $font-size);
    padding-right: scale-to-em(16px, $font-size);

    width: 100%;

    text-align: left;

    @media (max-width: $max-desktop) {
      padding-left: scale-to-em(16px, $font-size);
    }

    &:hover,
    &:active {
      color: $color-secondary;
    }
  }

  &__title {
    @include h4;
  }

  &__textWrap {
    height: 0;
    overflow: hidden;

    opacity: 0;

    @media (prefers-reduced-motion: reduce) {
      opacity: 1;

      .active & {
        height: auto;
      }
    }
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: auto;

    min-width: scale-to-em($indicator-min, $font-size);
    min-height: scale-to-em($indicator-min, $font-size);

    border-radius: 50%;

    transition: background-color var(--transition-duration) $default-ease;

    .active & {
      background-color: $color-background;
    }

    @media (min-width: $min-tablet) {
      min-width: scale-to-em($indicator, $font-size);
      min-height: scale-to-em($indicator, $font-size);
    }
  }

  &__svg {
    width: scale-to-em(15px, $font-size);
    height: scale-to-em(15px, $font-size);

    color: $color-secondary;

    @media (min-width: $min-tablet) {
      width: scale-to-em(20px, $font-size);
      height: scale-to-em(20px, $font-size);
    }
  }

  &__text {
    padding-top: 0;
    padding-right: scale-to-em($gutter, $font-size);
    padding-bottom: scale-to-em($gutter, $font-size);
  }

  @media print {
    &__title {
      font-size: $h3-size-min;
    }

    &__indicator {
      display: none;
    }

    &__textWrap {
      height: auto;

      opacity: 1;
    }

    &__text {
      padding: 0;
    }
  }
}
