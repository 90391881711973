@import 'styles/config';

.chartItem {
  margin-top: calc($gutter * 2);

  width: 100%;

  &.leading {
    padding: 0 $gutter 0 0;

    @media (min-width: $min-wide) {
      padding: 0 $gutter;
    }
  }

  &__title {
    @include h4;
    padding-top: calc($gutter * 0.5);

    border-top: 4px solid $color-green;

    font-weight: 900;

    color: $color-green;
  }

  &__content {
    margin-top: calc($gutter * 0.5);

    width: 100%;

    font-size: $font-size-min;

    color: $color-placeholder;
  }

  // Item nested inside parent (One layer deep)
  &.depthOne {
    padding-left: $gutter;

    @media (min-width: $min-wide) {
      padding-left: 0;
    }
  }

  // Item nested inside a child within parent (Two layers deep)
  &.depthTwo {
    padding-left: $gutter;

    @media (min-width: $min-wide) {
      padding-left: calc($gutter * 0.5);
    }
  }
}
