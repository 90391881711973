@import 'styles/config';

.transparentButton {

  @include hover-supported() {
    border: 2px solid white;

    transition: all 0.15s ease-in-out;

    .backgroundGradient {
      opacity: 1;

      transition: all 0.15s ease-in-out;
    }

    .buttonText {
      gap: #{$Px12Clamp};

      color: white;

      transition: all 0.15s ease-in-out;
    }

    .buttonArrowRight {
      color: white;
    }
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 14px 16px;

  height: 64px;
  overflow: hidden;

  border: 2px solid #EBE8ED;

  border-radius: 8px;

  font-size: clamp(0.833rem, 0.794rem + 0.223vw, 1rem);

  font-weight: 700;
  text-decoration: none;

  background: #{$color-background};
  background: white;
  color: #{$color-secondary};

  transition: all 0.15s ease-in-out;
}

.buttonSmall {
  padding: 16px 14px;

  height: 56px;
}

.backgroundGradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  opacity: 0;

  background: var(--fill-gradient);

  transition: all 0.15s ease-in-out;
}

.buttonText {
  display: flex;
  align-items: center;
  gap: 0;

  z-index: 2;
}

.buttonArrowRight {
  z-index: 2;

  color: #{$color-secondary};

  transition: all 0.15s ease-in-out;
}

.arrowSpringWrapper {
  display: flex;
  align-items: flex-end;
}

.cardIsOnHover {
  border: 2px solid white;

  transition: all 0.15s ease-in-out;
      
  .backgroundGradient {
    opacity: 1;

    transition: all 0.15s ease-in-out;
  }
      
  .buttonText {
    gap: #{$Px12Clamp};

    color: white;

    transition: all 0.15s ease-in-out;
  }
      
  .buttonArrowRight {
    color: white;
  }
}