@import '~styles/config';

.container {
  margin-top: 48px;
  margin-bottom: 128px;
  margin-left: -24px;
  padding-left: 24px;

  width: calc(100% + 48px);
  max-width: calc(100% + 48px);
  overflow-y: scroll;
  @media (min-width: $min-tablet) {
    margin-left: 0px;
    padding-left: 0px;

    width: 100%;
    max-width: 100%;
  }
}

.table {
  width: 100%;
}

.caption {
  @include h1;
  margin-bottom: 48px;

  text-align: left;
  &.smallerTitle {
    @include h3;
  }
}

.thead {
  @include h4;
  text-align: left;

  tr {
    th {
      padding: 24px 12px;

      min-width: 250px;

      font-weight: normal;

      background-color: $color-table-header;

      &:first-child {
        padding-left: 24px;

        border-top-left-radius: 8px;
      }

      &:last-child {
        padding-right: 24px;

        border-top-right-radius: 8px;
      }
    }
  }
}
.tbody {
  text-align: left;

  tr {
    td {
      padding: 24px 12px;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
    }

    &:nth-child(even) {
      td {
        background-color: $color-input-dropdown-open;
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
  a {
    text-decoration: none;

    color: var(--color-link, currentColor);

    &:hover {
      text-decoration: underline;
    }
  }
}
