@import 'styles/config';

.dataTable {
  font-size: scale-to-em($font-size-button, $font-size);
  
  &__heading {
    margin-bottom: $gutter;
  }

 
  thead {  
    background-color: $color-table-header;
    color: $color-secondary;
  }

  thead th {
    @include h5;
    border-top: 0;

    font-weight: normal;
  }

  &__footnote{
    margin-top: 24px;
    padding-top: 16px;

    border-top: 1px solid $color-grey-4;

    font-size: $font-size-input-min;
  }

  tbody {
    tr{
      &:nth-child(even) {
        td {
          background-color: $color-input-dropdown-open;
        }
      }
    }
  }

  table th:first-child {
    border-radius: 8px 0 0 0;
  }
  
  table th:last-child {
    border-radius: 0 8px 0 0;
  }

  table tr:last-child td:first-child {
    border-radius: 0px 0 0 8px;  }
  
  table tr:last-child td:last-child{
    border-radius: 0 0 8px 0;
  }

  th,
  td {
    padding: 24px;

    text-align: left;

  }

  .table-data-align-right th:not(:first-child),
  .table-data-align-right td:not(:first-child) {
    text-align: right;
  }
}
