@import '~styles/config';

.googlemapsContainer {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding-bottom: 96px;
  padding-top: 96px;
  margin-top: 32px;
  margin-bottom: 128px;
}

.googlemapsWithTable {
  margin-bottom: 128px;
}

.map {
  > div {
    border-radius: 8px;
  }
}

.title {
  @include h2;
  margin-bottom: 32px;
}

.description {
  margin-bottom: 16px;
}

.textWrapper {
  margin-bottom: 64px;

  a {
    color: $color-secondary;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}
