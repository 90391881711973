@import 'styles/config';

$responsive-padding: clamp(1.875rem, -2.033rem + 10.423vw, 8.813rem);

.powerPackageCtaLargeWrapper {
  border-radius: 8px;
  background: var(--greyscale-cool-cool-grey-3, #F5F5F5);

  padding-left: #{$responsive-padding};
  padding-right: #{$responsive-padding};
}

.title>h2 {
  font-size: clamp(1.75rem, 1.342rem + 1.087vw, 3rem);
}

.powerPackageCtaLargeTiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: clamp(1rem, 0.674rem + 0.87vw, 2rem);
  margin-top: clamp(2rem, 1.348rem + 1.739vw, 4rem);

  @media screen and (max-width: $screen-power-package-md) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

.powerPackageCtaSingleLineTiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: clamp(1rem, 0.674rem + 0.87vw, 2rem);
  margin-top: clamp(2rem, 1.348rem + 1.739vw, 4rem);

  @media screen and (max-width: $screen-power-package-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media screen and (max-width: $min-mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}