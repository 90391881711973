@import 'styles/config';

.greenLight {
  position: relative;

  scroll-margin: var(--header-height);

  &.full {
    min-height: calc(100vh - var(--header-height));
  }

  &__inner {
    @include grid;
    padding: calc(var(--gutter) * 4) 0;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      right: 0; // Fixes mobile navigation drawer, as -100% caused the mobile menu to overflow
      bottom: 0;
      left: -100%;
      z-index: -1;

      opacity: 0;

      background: linear-gradient(
        -135deg,
        #372962 0%,
        #27967f 32%,
        #39b080 88%,
        rgba(50, 5, 100, 0.5) 100%
      );

      transition: all 900ms ease 300ms, opacity 900ms ease-in-out;

      .greenLightSwitch & {
        left: 0;

        opacity: 1;
      }
    }
  }

  &__title {
    @include grid-item;
    @include responsive-font(35px, $h3-size);

    margin-bottom: var(--gutter);

    font-weight: 900;

    strong,
    b {
      font-weight: 900;

      color: $color-primary;

      .greenLightSwitch & {
        color: $color-font-contrast;
      }
    }

    @media (min-width: $min-desktop) {
      @include grid-item(5, 4);
      margin-bottom: var(--gutter);
    }
  }

  &__toggleButtonWrap {
    @include grid-item;

    margin-bottom: var(--gutter);

    @media (min-width: $min-desktop) {
      @include grid-item(2, 2);

      display: flex;
      justify-content: flex-end;

      margin: 0;
      padding-top: scale-to-em(10px);
    }
  }

  &__toggleButton {
    position: relative;

    padding: 35px 30px;

    min-width: 140px;

    border-radius: 60px;

    background-color: $color-primary;

    transition: all 0.3s ease-in-out;

    &:hover,
    &:active {
      background-color: darken($color-primary, 5%);
    }

    .greenLightSwitch & {
      background-color: $color-secondary;
    }
  }

  &__iconCircleWrap {
    display: flex;

    align-items: center;
    justify-content: center;

    position: absolute;

    top: 0;
    bottom: 0;
    left: 10px;

    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;

    width: 55px;
    height: 55px;

    border-radius: 50%;

    background-color: white;

    transition: all 0.3s ease-in-out;

    .greenLightSwitch & {
      margin-left: calc(100% - 75px);
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    fill: $color-primary;

    & path {
      fill: $color-primary;

      transition: all 0.3s ease;

      .greenLightSwitch & {
        fill: $color-secondary;
      }
    }
  }

  &__intro {
    @include grid-item;

    margin-bottom: calc(var(--gutter) * 2);

    line-height: 30px;

    transition: all 0.3s ease;

    @media (min-width: $min-desktop) {
      @include grid-item(5, 4);
    }

    .greenLightSwitch & {
      color: $color-font-contrast;
    }
  }

  &__roundedButtonsContainer {
    @include grid-item;

    display: flex;
    flex-direction: column;
    gap: calc(var(--gutter) * 0.5);

    @media (min-width: $min-desktop) {
      @include grid-item(8, 4);
      flex-direction: row;
    }

    .full & {
      margin-bottom: calc(var(--gutter) * 2);

      @media (min-width: $min-desktop) {
        margin-bottom: calc(var(--gutter) * 4);
      }
    }
  }

  &__roundedButton {
    padding: 15px 40px;

    width: max-content;

    border-radius: 90px;

    cursor: pointer;

    background-color: $color-secondary;

    color: $color-font-contrast;

    transition: all 0.3s ease;

    &:link {
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;

      background-color: $color-primary;
    }
  }

  &__text {
    @include grid-item;
    @include h2;

    font-weight: 900;
    line-height: 1.4;

    color: $color-primary;

    transition: all 0.3s ease;

    @media (min-width: $min-desktop) {
      @include grid-item(8, 2);
      padding-left: var(--gutter);
    }

    @media (min-width: $min-wide) {
      @include grid-item(6, 2);

      padding-left: calc(var(--gutter) * 3);
    }

    .greenLightSwitch & {
      color: $color-font-contrast;
    }
  }
}
