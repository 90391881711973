@import 'styles/config';

.staffItem {
  &__imageWrap {
    position: relative;

    margin-bottom: var(--gutter);

    &::before {
      @include aspect-ratio(2, 3);
      content: '';

    }
  }

  &__image {
    @include fit;
    @include media-position;
    border-radius: 8px;
  }

  &__name {
    margin-bottom: scale-to-em(20px, $font-size);

    font-weight: 900;
  }

  &__title,
  &__tel {
    color: var(--color-dark-grey);
  }

  &__title,
  &__tel,
  &__email {
    @include responsive-font($font-size-min, 16px);
    line-height: scale-to-em(27px, $font-size-min);
  }

  &__email {
    color: var(--color-secondary);
  }

  &__tel,
  &__email {
    width: max-content;

    &:hover {
      cursor: pointer;

      color: var(--color-primary);

      transition: all $transition-duration ease;
    }
  }

  &__link {
    text-decoration: none;
  }
}
