@import 'styles/config';

.staff {
  &__title {
    margin-bottom: 25px;
  }

  &__description {
    margin-bottom: calc(var(--gutter) * 2);
  }

  &__children {
    row-gap: calc(var(--gutter) * 3);
  }
}
