@import 'styles/config';

.onboardingCTA {
  &__title {
    @include transition-enter-on-active;
    @include h2;

    transform: translate3d(0, 20px, 0);

    > b {
      color: $color-green;
    }
  }

  &__text {

    @include transition-enter-on-active;
    margin-bottom: scale-to-em(40px, $font-size);

    transform: translate3d(0, 20px, 0);

    &:not(:first-child) {
      padding-top: scale-to-em(40);

      transition-delay: $stagger-duration;
    }
  }

  &__link {
    display: block;

    padding: $gutter;

    border: 1px solid #d5d5d5;

    font-weight: 600;
    text-align: center;
    text-decoration: none;

    background-color: $color-input-background;
    color: $color-green-dark;

    + &__link {
      margin-top: -1px;
    }

    &:hover,
    &:active {
      background-color: $color-green-dark;
      color: $color-input-background;
    }
  }
}
