$paddingY: clamp(2rem, 0.696rem + 3.478vw, 6rem);
$paddingX: clamp(1rem, 0.266rem + 1.957vw, 3.25rem);

.sliceContainer {
  margin: auto;
  max-width: 1664px;
}

.defaultPadding {
  padding: #{$paddingY} #{$paddingX};
}