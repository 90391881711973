@import 'styles/config';

.chart {
  &__title {
    margin-bottom: scale-to-em(25, $h2-size);
  }

  &__description {
    margin-bottom: calc($gutter * 2);
  }

  &__container {
    position: relative;

    padding-bottom: $gutter;
  }

  &__line {
    @media (min-width: $min-wide) {
      position: relative;

      margin: 0 calc($gutter * -0.5 - 1px) 0 calc($gutter * -0.5);

      padding-top: $gutter;

      &::before {
        content: '';

        position: absolute;
        bottom: calc($gutter * -1);

        width: 100%;

        height: 1px;

        background: $color-chart-line;
      }
    }
  }

  &__subContainer {
    display: flex;
    flex-direction: column;

    position: relative;

    &::before {
      content: '';

      position: absolute;

      top: $gutter;
      bottom: 0;

      left: auto;

      width: 1px;

      height: 100%;

      background: $color-chart-line;

      @media (min-width: $min-wide) {
        top: 0;
        left: 50%;

        height: $gutter;
      }
    }

    @media (min-width: $min-wide) {
      &.middle {
        &::before {
          top: calc($gutter * -1);

          height: calc($gutter * 2);
        }
      }
    }
  }

  // Nested sub list (child of sub container)
  &__subList {
    position: relative;

    padding-left: $gutter;

    &::before {
      content: '';

      position: absolute;
      top: $gutter;
      bottom: 0;

      width: 1px;
      height: 100%;

      background: $color-chart-line;
    }

    @media (min-width: $min-wide) {
      padding-left: 0;
    }
  }
}
