@import 'styles/config';

.accordion {
  position: relative;
  z-index: 0;

  &:empty {
    display: none;
  }

  &__inner {
    @include grid;
  }

  &__title {
    @include grid-item;
    @include h2;

    margin-bottom: $gutter;

    @media (min-width: $min-desktop) {
      @include grid-item(3, 1);
    }
  }

  &__list {
    @include grid-item;

    @media (min-width: $min-desktop) {
      @include grid-item(7, 4);
    }
  }
}
