@import 'styles/config';

.container {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 16px 0px #00000014;
  padding: 32px;
  padding-bottom: 16px;
  font-family: $font-family-sans;
  background-color: white;
  border-radius: 16px;
  min-width: 340px;
  margin-bottom: 4px;
  .title {
    @include h3;
    margin-bottom: 8px;
  }
  a {
    color: $color-secondary;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }
  .description {
    display: block;
    font-size: 10px;
    color: $color-font;
    font-weight: 350;
  }
  .linkContainer {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .linkContainer:has(a:hover) {
    .iconContainer {
      background-color: #cdc4d7;
    }
  }
  .divider {
    margin-bottom: 24px;
    margin-top: 0;
    border-color: #e5e1ea;
  }
  .iconContainer {
    background-color: #ebe8ed;
    transition: background-color 320ms ease;
    border-radius: 99px;
    margin-right: 8px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  dt {
    font-size: $font-size-xs;
    font-weight: 350;
    font-family: $font-family-sans;
  }
  dd {
    font-size: $font-size;
    color: $color-secondary;
    font-weight: 400;
    font-family: $font-family-sans;
    margin-bottom: 16px;
    &:last-child() {
      margin-bottom: 0px;
    }
  }
}
